.other-blogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .blog-item {
    width: calc(33.3333% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .blog-item img {
    width: 100%;
    height: auto;
    max-height: 170px;
  }
  
  .blog-item p {
    margin-top: 0px;
    text-align: center;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;
    line-height: 1rem;
  }

  .blog-item a {
    text-decoration: none !important;
  }

  .blog-item a:hover {
    text-decoration: underline !important;
  }
  
  .blog-item .category {
        font-size: 10px;
        font-weight: 600;
        color: #ccc;
        text-align: center;
        margin-top: 0px;
  }
  @media (max-width: 768px) {
    .blog-item {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 480px) {
    .blog-item {
      width: 49%;
      float: left;
      box-sizing: border-box;
    }

    .blog-item img {
      max-height: 100px;
    }

    .blog-item + .blog-item {
      gap: 2px;
    }

    .blog-item .category {
      display: none;
    }
    .blog-item p {
      font-size: 20px!important;
      line-height: 1.5rem;
    }
    .blog-item .category {
      display: none;
    }
  }
  