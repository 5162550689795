.offer_post {
   margin: 20px auto;
}

.offer_post h2 {
    display: flex;
    line-height: 55px;
    font-size: 30px !important;
    text-transform: capitalize !important;
   line-height: 55px;
   font-size: 30px !important;
   text-transform: capitalize !important;
    text-decoration: none;
    color: #000000 !important;
}

.offer_post .title__link {
    text-decoration: none;
    color: #000000;
}

.offer_post .title__link:hover {
    text-decoration: underline;
    /* color: #0e22a4; */
}

.offer_image img {
   max-width: 70%;
   margin: 20px;
   float: right;
}

.unit {
    background-color: #595959;
    color: #ffffff;
    padding: 8px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 5px;
}

a {
    text-decoration: underline;
    color: #207aff;
    font-weight: bold;
    letter-spacing: .01em;
}

.button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    /* background-color: #db2828; */
    background: linear-gradient(160deg,#00FFDC,#26C3F7,#279DD7,#2685F7,#267BF7,#1C77E4,#2269BE,#135CB4);
    padding: 14px 0;
    font-size: 1.71428571rem;

   background-color: #4e5497;
   color: #fff;
   padding: 8px 15px;
   margin-right: 20px;
}

.button {
   width: 100%;
   color: #fff;
   border-radius: 4px;
   font-weight: bold;
   display: block;
   border: none;
   text-align: center;
   cursor: pointer;
   text-decoration: none;
   overflow: hidden;
   background-color: #cecece;
   padding: 14px 0;
}

p.offer_content {
   line-height: 2rem;
   font-size: 1.2rem;
}

@media only screen and (max-width: 640px) {
   p.offer_content {
      line-height: 1.5rem;
      font-size: 1rem;
   }

   .offer_image img {
      max-width: 100%;
      margin: 5px;
   }
}

.button:hover {
    background-color: #f60707;
}

@media (max-width: 600px) {
    .offer_image img {
        margin: 0px auto;
    }

    .offer_post h2 {
        font-size: 25px !important;
    }
}