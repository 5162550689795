.main_container {
   background-color: white;
   height: 100%;
   padding: 20px 0;
}

.main_sec_container {
   max-width: 1000px;
   margin-left: auto;
   margin-right: auto;
   background-color: #fff;
   padding: 20px;
   height: auto;
}
