footer {
   background-color: #3e4753;
   color: white;
   font-size: 1rem;
   padding: 75px 20px 100px 20px;
   text-align: center;
}

.footer_logo img {
   width: 30%;
   font-size: 40px;
   margin-bottom: 2rem;
}

.link_items {
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   margin-top: 20px;
   margin-bottom: 20px;
}

.link_items > li {
   text-decoration: none;
   list-style: none;
   margin-left: 10px;
   margin-right: 10px;
   font-size: 1.2rem;
}

.sec_container span {
   display: block;
   margin-top: 30px;
   font-size: 1.2rem;
   line-height: 2rem;
   color: white;
}

.sec_container a {
   color: #f2bb48;
}
