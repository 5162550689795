header {
   height: 150px;
   padding-left: 30px;
   padding-right: 30px;
   background-color: #0d334c;
   color: white;
   display: flex;
   flex-direction: column;
}

.sec_container {
   max-width: 800px;
   width: 50%;
   margin-left: auto;
   margin-right: auto;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.top_small_txt {
   font-size: 1.15rem;
   color: #999;
   text-align: center;
   margin: 0;
}

.logo {
    /* padding: 20px 0; */
    max-width: 100%;
    width: 25%;
    position: relative;
    /* font-size: 40px;
    font-weight: 900; */
}

.logo img {
    max-width: 100%;
}

@media (max-width: 600px) {
    .logo {
        width: 60%;
    }
}
